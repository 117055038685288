import { Period } from '../../types'
import { APP_IMAGE_URL_PREFIX } from '../../utility/constants'

import RouteLink from './RouteLink'
import {
  AnalyticsGraphChart,
  CalendarAppointmentDate,
  EducationSchool,
  Info,
  PaperNote,
  ShoppingCart,
} from 'react-basicons'
import { useNavigate } from 'react-router-dom'

/**
 * Component for the header of the teacher pages.
 */
function StaffHeader({
  isSlim,
  isSchoolAdmin,
  isStoreManager,
  isTeacher,
  isGiveAll,
  hasTeacherUsageReports,
  hasSchoolStudentReports,
  hasSchoolDashboard,
  periods,
}: {
  isSlim: boolean
  isSchoolAdmin: boolean
  isStoreManager: boolean
  isTeacher: boolean
  isCustomizeStore: boolean
  isGiveAll: boolean
  hasTeacherUsageReports: boolean
  hasSchoolStudentReports: boolean
  hasSchoolDashboard: boolean
  periods: Period[]
}) {
  const navigate = useNavigate()
  const ALL_ROLES = [
    'TEACHER',
    'SCHOOL_ADMIN',
    'STORE_MANAGER',
    'GIVE_ALL',
    'CUSTOMIZE_STORE',
    'SCHOOL_STUDENT_REPORTS',
    'TEACHER_USAGE_REPORTS',
    'SCHOOL_DASHBOARD',
  ]

  const pages = [
    {
      name: 'Bizi Bonuses & Flags',
      link: '/bonuses-flags',
      icon: <img height={22} src={`${APP_IMAGE_URL_PREFIX}/BonusIconBlack.svg`} />,
      roles: ['SCHOOL_ADMIN', 'GIVE_ALL'],
      hideFor: [],
      disabled: false,
    },
    {
      name: 'Classes',
      link: '/classes',
      icon: <EducationSchool size={22} />,
      roles: ['TEACHER'],
      // Hide when not asscoiated with any period
      disabled: periods.length === 0,
    },
    {
      name: 'Store',
      link: '/store',
      icon: <ShoppingCart size={22} />,
      roles: ALL_ROLES,
      disabled: false,
    },
    {
      name: 'Orders',
      link: '/orders',
      icon: <PaperNote size={22} />,
      roles: ['TEACHER', 'STORE_MANAGER'],
      disabled: false,
    },
    {
      name: 'Reports',
      link: '/reports',
      icon: <AnalyticsGraphChart size={22} />,
      roles: ['TEACHER', 'SCHOOL_STUDENT_REPORTS', 'TEACHER_USAGE_REPORTS'],
      disabled: periods.length === 0 && !hasTeacherUsageReports && !hasSchoolStudentReports,
    },
    {
      name: 'Calendar',
      link: '/calendar',
      icon: <CalendarAppointmentDate size={22} />,
      roles: ALL_ROLES,
      disabled: false,
    },
    {
      name: 'How To Earn',
      link: '/how-to-earn',
      icon: <Info size={22} />,
      roles: ALL_ROLES,
      disabled: false,
    },
  ]

  return (
    <div>
      {pages.map((page) => {
        if (page.disabled) {
          return null
        }
        if (
          (page.roles.includes('TEACHER_USAGE_REPORTS') && hasTeacherUsageReports) ||
          (page.roles.includes('SCHOOL_STUDENT_REPORTS') && hasSchoolStudentReports) ||
          (page.roles.includes('TEACHER') && isTeacher) ||
          (page.roles.includes('SCHOOL_ADMIN') && isSchoolAdmin) ||
          (page.roles.includes('STORE_MANAGER') && isStoreManager) ||
          (page.roles.includes('SCHOOL_DASHBOARD') && hasSchoolDashboard) ||
          (page.roles.includes('GIVE_ALL') && isGiveAll)
        ) {
          return (
            <RouteLink
              isSlim={isSlim}
              key={page.link}
              {...page}
              onClick={() => {
                navigate(page.link)
              }}
            />
          )
        }
        return null
      })}
    </div>
  )
}
export default StaffHeader
