import {
  PayPeriod,
  Quarter,
  Period,
  ActvityType,
  PeriodStudent,
  Group,
  ActvityTypePointed,
} from '../types'

import { ActivityCategory, MONTH_NAMES, UserRole } from './constants'
import * as Sentry from '@sentry/react'
import { lazy } from 'react'
import ReactGA from 'react-ga4'

/**
 * Retrieves the suffix for a given period number.
 * @param n - The period number.
 * @return The suffix for the period number.
 */
const getPeriodSuffix = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}

/**
 * Retrieves the current time in the user's timezone.
 * @returns The current time in the user's timezone.
 */
function getUserTime(dateStr?: string) {
  // Get the current date and time
  const now = dateStr ? new Date(dateStr) : new Date()
  // Get the user's timezone offset in minutes
  const timezoneOffset = now.getTimezoneOffset()
  // Calculate the time difference between UTC and the user's timezone
  const timezoneDifference = timezoneOffset / ((dateStr ? 1 : -1) * 60)
  // Get the current time in the user's timezone
  const userTime = new Date(now.getTime() + timezoneDifference * 60 * 60 * 1000)
  return userTime
}

/**
 * Retrieves the current time span(period or quarter) based on the current date.
 * @param timeSpans - An array of quarters or periods.
 * @return Current quarter or period
 */
const getCurrentTimeSpan = (timeSpans: PayPeriod[] | Quarter[]): PayPeriod => {
  // Get current date in user timezone
  const today = new Date()
  const todayTimestamp = today.getTime()
  for (let i = 0; i < timeSpans.length; i++) {
    const payPeriod = timeSpans[i]
    const startDate = getUserTime(payPeriod.start)
    const endDate = getUserTime(payPeriod.end)
    const startDateTimestamp = startDate.getTime()
    const endDateTimestamp = endDate.setHours(23, 23, 59, 59)
    if (todayTimestamp >= startDateTimestamp && todayTimestamp <= endDateTimestamp) {
      return payPeriod
    }
  }

  let previousPayPeriod = timeSpans[0]
  for (let i = 0; i < timeSpans.length; i++) {
    const payPeriod = timeSpans[i]
    const startDate = getUserTime(payPeriod.start)
    const startDateTimestamp = startDate.getTime()
    if (todayTimestamp < startDateTimestamp) {
      return payPeriod
    }
    previousPayPeriod = payPeriod
  }

  return previousPayPeriod
}

/**
 * Retrieves the previous time span(period or quarter) based on the current date.
 * @param timeSpans - An array of quarters or periods.
 * @return Previous quarter or period
 */
const getPreviousTimeSpan = (
  timeSpans: PayPeriod[] | Quarter[],
  givenTimeSpan: PayPeriod | Quarter
): PayPeriod => {
  const currentPayPeriodIndex = timeSpans.findIndex(
    (timeSpan) => timeSpan._id === givenTimeSpan._id
  )
  if (currentPayPeriodIndex === 0) {
    return timeSpans[0]
  }
  return timeSpans[currentPayPeriodIndex - 1]
}

/**
 * Retrieves the name of a period.
 * If the period number is 0, the name is "Homeroom".
 * If the period number is 99, the name is "Other".
 * Otherwise, the name is "{periodNumber}{periodSuffix} Period".
 * @param isCoTeacher - Indicates if the user is a co-teacher.
 * @param period - The period metadata.
 * @return The name of the period.
 */
const getPeriodName = (isCoTeacher: boolean, period: Period): string => {
  if (isCoTeacher) {
    return period.coTeacherDisplayName
  }
  const periodName = `${period.periodNumber}${getPeriodSuffix(period.periodNumber)} Period`
  if (periodName === '0th Period') {
    return `Homeroom`
  }
  if (periodName === '99th Period') {
    return 'Other'
  }
  return periodName
}

/**
 * Get the period number from the internal period id.
 * Internal period id is in the format of "period-1" or "1"
 * @param internalPeriodId internal period id
 * @return period number
 */
const getPeriodNumber = (internalPeriodId: string) => {
  const splitStr = internalPeriodId.split('-')
  return splitStr.length > 0 ? splitStr[splitStr.length - 1] : 0
}

/**
 * Get the short name of a full name
 * @param fullName full name
 * @return short name
 */
const getShortName = (fullName: string) => {
  const splitStr = fullName.split(' ')
  return `${splitStr[0]} ${splitStr[1]?.charAt(0)}`
}

/**
 * Get the short name of a full name
 *
 * @param fullName full name
 * @return short name
 */
const getTeacherShortName = (fullName: string) => {
  const splitStr = fullName.split(' ')
  if (splitStr.length > 1) {
    return `${splitStr[0]?.charAt(0)}. ${splitStr[1]}`
  }
}

/**
 * Get the member status based on the balance
 * @param balance balance of the member
 * @return member status
 */
const getMemberStatus = (
  balance: number,
  memberStatusMap: { [key: string]: number } | undefined
) => {
  if (balance < silverThreshold(memberStatusMap)) {
    return 'Bronze'
  } else if (balance < goldThreshold(memberStatusMap)) {
    return 'Silver'
  } else if (balance < platinumThreshold(memberStatusMap)) {
    return 'Gold'
  } else if (balance < rubyThreshold(memberStatusMap)) {
    return 'Platinum'
  } else if (balance < emeraldThreshold(memberStatusMap)) {
    return 'Ruby'
  } else if (balance < sapphireThreshold(memberStatusMap)) {
    return 'Emerald'
  } else if (balance < diamondThreshold(memberStatusMap)) {
    return 'Sapphire'
  } else if (balance < onyxThreshold(memberStatusMap)) {
    return 'Diamond'
  } else {
    return 'Onyx'
  }
}

/**
 * Get all member status before the given status. If the given status is not found, return Bronze
 * @param memberStatus member status
 * @return all member status before the given status
 */
const getAllMemberStatusBeforeGivenStatus = (memberStatus: string) => {
  switch (memberStatus) {
    case 'Bronze':
      return ['Bronze']
    case 'Silver':
      return ['Bronze', 'Silver']
    case 'Gold':
      return ['Bronze', 'Silver', 'Gold']
    case 'Platinum':
      return ['Bronze', 'Silver', 'Gold', 'Platinum']
    case 'Ruby':
      return ['Bronze', 'Silver', 'Gold', 'Platinum', 'Ruby']
    case 'Emerald':
      return ['Bronze', 'Silver', 'Gold', 'Platinum', 'Ruby', 'Emerald']
    case 'Sapphire':
      return ['Bronze', 'Silver', 'Gold', 'Platinum', 'Ruby', 'Emerald', 'Sapphire']
    case 'Diamond':
      return ['Bronze', 'Silver', 'Gold', 'Platinum', 'Ruby', 'Emerald', 'Sapphire', 'Diamond']
    case 'Onyx':
      return [
        'Bronze',
        'Silver',
        'Gold',
        'Platinum',
        'Ruby',
        'Emerald',
        'Sapphire',
        'Diamond',
        'Onyx',
      ]
    default:
      return ['Bronze']
  }
}

const bronzeThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Bronze ?? 0

const silverThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Silver ?? 1000

const goldThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Gold ?? 2000

const platinumThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Platinum ?? 3000

const rubyThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Ruby ?? 4000

const emeraldThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Emerald ?? 5000

const sapphireThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Sapphire ?? 6000

const diamondThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Diamond ?? 7000

const onyxThreshold = (memberStatusMap: { [key: string]: number } | undefined) =>
  memberStatusMap?.Onyx ?? 8000

/**
 * Get the amount to next status based on the balance
 * @param balance balance of the member
 * @return amount to next status
 */
const getAmountToNextStatus = (
  balance: number,
  memberStatusMap: { [key: string]: number } | undefined
) => {
  if (balance < silverThreshold(memberStatusMap)) {
    return silverThreshold(memberStatusMap) - balance
  } else if (balance < goldThreshold(memberStatusMap)) {
    return goldThreshold(memberStatusMap) - balance
  } else if (balance < platinumThreshold(memberStatusMap)) {
    return platinumThreshold(memberStatusMap) - balance
  } else if (balance < rubyThreshold(memberStatusMap)) {
    return rubyThreshold(memberStatusMap) - balance
  } else if (balance < emeraldThreshold(memberStatusMap)) {
    return emeraldThreshold(memberStatusMap) - balance
  } else if (balance < sapphireThreshold(memberStatusMap)) {
    return sapphireThreshold(memberStatusMap) - balance
  } else if (balance < diamondThreshold(memberStatusMap)) {
    return diamondThreshold(memberStatusMap) - balance
  } else if (balance < onyxThreshold(memberStatusMap)) {
    return onyxThreshold(memberStatusMap) - balance
  } else {
    return 0
  }
}

/**
 * Get the level threshold based on the level
 * @param level level of the member
 * @param memberStatusMap member status map
 * @return level threshold
 */
const getLevelThreshold = (
  level: string,
  memberStatusMap: { [key: string]: number } | undefined
) => {
  switch (level) {
    case 'Bronze':
      return bronzeThreshold(memberStatusMap)
    case 'Silver':
      return silverThreshold(memberStatusMap)
    case 'Gold':
      return goldThreshold(memberStatusMap)
    case 'Platinum':
      return platinumThreshold(memberStatusMap)
    case 'Ruby':
      return rubyThreshold(memberStatusMap)
    case 'Emerald':
      return emeraldThreshold(memberStatusMap)
    case 'Sapphire':
      return sapphireThreshold(memberStatusMap)
    case 'Diamond':
      return diamondThreshold(memberStatusMap)
    case 'Onyx':
      return onyxThreshold(memberStatusMap)
    default:
      return 0
  }
}

/**
 * Get the next status based on the balance
 * @param balance balance of the member
 * @param memberStatusMap member status map
 * @return next status
 */
const getNextStatus = (balance: number, memberStatusMap: { [key: string]: number } | undefined) => {
  if (balance < silverThreshold(memberStatusMap)) {
    return 'Silver'
  } else if (balance < goldThreshold(memberStatusMap)) {
    return 'Gold'
  } else if (balance < platinumThreshold(memberStatusMap)) {
    return 'Platinum'
  } else if (balance < rubyThreshold(memberStatusMap)) {
    return 'Ruby'
  } else if (balance < emeraldThreshold(memberStatusMap)) {
    return 'Emerald'
  } else if (balance < sapphireThreshold(memberStatusMap)) {
    return 'Sapphire'
  } else if (balance < diamondThreshold(memberStatusMap)) {
    return 'Diamond'
  } else if (balance < onyxThreshold(memberStatusMap)) {
    return 'Onyx'
  }
}

/**
 * Get the member status color based on level
 * @param balance balance of the member
 * @return member status color
 */
const getMemberStatusColor = (level: string) => {
  switch (level) {
    case 'Bronze':
      return '#F59E0B'
    case 'Silver':
      return '#E8E8E8'
    case 'Gold':
      return '#EAB506'
    case 'Platinum':
      return '#6F7176'
    case 'Ruby':
      return '#EC4899'
    case 'Emerald':
      return '#0AAEB3'
    case 'Sapphire':
      return '#3B9CF6'
    case 'Diamond':
      return '#6366F1'
    case 'Onyx':
      return '#2D3748'
    default:
      return '#F59E0B'
  }
}

/**
 * Get the member status text color based on level
 * @param balance balance of the member
 * @return member status color
 */
const getMemberStatusTextColor = (level: string) => {
  switch (level) {
    case 'Silver':
      return 'var(--black)'
    default:
      return getMemberStatusColor(level)
  }
}

/**
 * Get valid time spans
 * @param timeSpans time spans to filter
 * @return valid time spans
 */
const getValidTimeSpans = (timeSpans: Quarter[] | PayPeriod[]) => {
  return timeSpans.filter(
    (timeSpan) => new Date(timeSpan.start).getTime() < getUserTime().getTime()
  )
}

/**
 * Capture error with Sentry
 * @param error error to capture
 * @return void
 */
const captureSentryException = (error: Error) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}

/**
 * Capture message with Sentry
 * @param error error to capture
 * @return void
 */
const captureSentryMessage = (message: string) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureMessage(message)
  } else {
    console.info(message)
  }
}

/**
 * Remove keys from local storage
 * 1. authtoken
 * 2. biziUserId
 * @return void
 */
const removeLocalStorage = () => {
  const keysToRemove = ['authtoken', 'biziUserId']
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key)
  })
}

/**
 * Remove keys from session storage
 * 1. STUDENT_SATISFACTION_SURVEY
 * @return void
 */
const removeSessionStorage = () => {
  const keysToRemove = ['STUDENT_SATISFACTION_SURVEY']
  keysToRemove.forEach((key) => {
    sessionStorage.removeItem(key)
  })
}

/**
 * Logout the user
 * @return void
 */
const logout = () => {
  Sentry.setUser(null)
  removeLocalStorage()
  removeSessionStorage()
  window.location.href = '/login'
}

const getCurrencyName = () => 'BiziBucks'

/**
 * Get the date string.
 * @param date date
 * @return date string in the format of "Month Day"
 */
const getDateStr = (date: Date) => {
  return `${MONTH_NAMES[date.getMonth()]} ${date.getDate()}`
}

const isProd = () => window.location.origin.includes('app.bizimotivates.com')

/**
 * Get the last four digits of a number
 * @param number number
 * @return last four digits
 */
function getLastFourDigits(number: number) {
  // Convert the number to a string
  const numberString = String(number)

  // Get the last four characters (digits) of the string
  const lastFourDigits = numberString.slice(-4)

  // Parse the last four digits as an integer
  const lastFourDigitsInt = parseInt(lastFourDigits, 10)

  return lastFourDigitsInt
}

/**
 * Get the first four digits of a number
 * @param number number
 * @return first four digits
 */
function getFirstFourDigits(number: number) {
  // Convert the number to a string
  const numberString = String(number)

  // Get the first four characters (digits) of the string
  const firstFourDigits = numberString.slice(0, 4)

  // Parse the first four digits as an integer
  const firstFourDigitsInt = parseInt(firstFourDigits, 10)

  return firstFourDigitsInt
}

/**
 * Send custom event to Google Analytics
 * @param category event category
 * @param action event action
 * @return void
 */
function sendCustomEvent(category: 'click' | 'login' | 'submit' | 'scroll', action: string) {
  if (!isProd()) {
    console.info(`Custom event: ${category} - ${action}`)
  }
  ReactGA.event({
    category,
    action,
  })
}

/**
 * Attendance codes and their descriptions
 */
const attendanceCodes = new Map([
  ['Attendance P', 'Present'],
  ['Attendance Q', 'Present - Stay at Home Order'],
  ['Attendance T', 'Tardy - Excused'],
  ['Attendance S', 'School Related Activity'],
  ['Attendance ?', 'Assumed to be Present'],
  ['Attendance I', 'In-School Suspension'],
  ['Attendance O', 'Suspension - Out of School'],
  ['Attendance U', 'Absence - Unexcused'],
  ['Attendance E', 'Absence - Excused'],
  ['Attendance C', 'Absence - Check out'],
  ['Attendance L', 'Tardy - Unexcused'],
  ['Attendance H', 'HomeHosp Out of District'],
])

/**
 * Get the description of an attendance code.
 * If the attendance code is not found, return the code itself.
 * @param code attendance code
 * @return attendance description
 */
function getAttendanceDescription(code: string) {
  return attendanceCodes.get(code) ?? code
}

/**
 * Lazy load a component with retry.
 * If the component fails to load, the page is reloaded.
 * @param componentImport component import
 * @return lazy loaded component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') ?? 'false'
    )

    try {
      const component = await componentImport()

      window.localStorage.setItem('page-has-been-force-refreshed', 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true')
        return window.location.reload()
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })

/**
 * Get the name string. The name string is in the format of "Last Name, First Name"
 * @param fullName full name
 * @return name string
 */
const getNameStr = (fullName: string) => {
  const names = fullName.replace(/\s+/g, ' ').split(' ')
  const firstName = names[0] + (names.length > 2 ? ` ${names[1]}` : '')
  const lastName = names[names.length - 1]
  return `${lastName}, ${firstName}`
}

/**
 * Get the daily bizi bonus amount from the automated allocations
 * @param automatedAllocations automated allocations
 * @return daily bizi bonus amount
 */
const getDailyBiziBonusAmount = (
  automatedAllocations?: ActvityTypePointed[],
  activityType?: string
) => {
  const categoryBiziBonus = automatedAllocations?.find(
    (allocation) => allocation.activityType === activityType
  )
  if (categoryBiziBonus) {
    return categoryBiziBonus.points
  }
  const dailyBiziBonus = automatedAllocations?.find(
    (allocation) => allocation.category === ActivityCategory.BIZI_BONUS
  )
  return dailyBiziBonus?.points ?? 0
}

/**
 * Get the flag points based on the user roles
 * @param automatedAllocations automated allocations
 * @param userRoles user roles
 * @return flag points
 */
const getFlagPoints = (automatedAllocations?: ActvityTypePointed[], userRoles?: string[]) => {
  if (!userRoles || !automatedAllocations) {
    return 0
  }

  const flagConfigs =
    automatedAllocations?.filter((allocation) => allocation.category === ActivityCategory.FLAGS) ??
    []
  if (flagConfigs.length === 0) {
    return 0
  }
  if (userRoles.includes(UserRole.SCHOOL_ADMIN)) {
    return flagConfigs.find((flagConfig) => flagConfig.activityType === 'Admin Flag')?.points ?? 0
  } else if (userRoles.includes(UserRole.STAFF)) {
    return flagConfigs.find((flagConfig) => flagConfig.activityType === 'Staff Flag')?.points ?? 0
  } else if (userRoles.includes(UserRole.TEACHER)) {
    return flagConfigs.find((flagConfig) => flagConfig.activityType === 'Teacher Flag')?.points ?? 0
  }
  return 0
}

/**
 * Get the teacher name from the co-teacher display name.
 * @param coTeacherDisplayName
 * @returns
 */
const getTeacherName = (coTeacherDisplayName: string) => {
  const regex = /\((.*?)\)/ // Match anything within parentheses
  const match = regex.exec(coTeacherDisplayName)

  if (match) {
    const nameWithinBrackets = match[1]
    return nameWithinBrackets
  } else {
    return coTeacherDisplayName
  }
}

/**
 * Calculate the ratio of two numbers.
 * @param num1
 * @param num2
 * @return string
 */
function calculateRatio(num1: number, num2: number) {
  if (num2 === 0 || num1 === 0) {
    return `${num1}:${num2}`
  }

  // Divide the larger number by the smaller number
  const divisor = num1 > num2 ? num2 : num1

  // Divide both numbers by the GCD to get the simplified ratio
  const simplifiedNum1 = num1 / divisor
  const simplifiedNum2 = num2 / divisor

  return `${Math.ceil(simplifiedNum1)}:${Math.ceil(simplifiedNum2)}`
}

const getActivityObject = (
  period: Period,
  studentData: PeriodStudent,
  activityTypeConfig: ActvityType,
  points: number,
  teacherFullName: string,
  group?: Group,
  teamId?: string
) => {
  return {
    student: studentData?.studentId,
    externalStudentId: studentData?.externalStudentId as string,
    gradeLevel: studentData?.gradeLevel,
    house: studentData?.house,
    studentFullName: studentData?.fullName,
    teacherFullName: teacherFullName,
    activityType: activityTypeConfig.activityType,
    category: activityTypeConfig.category,
    internalPeriodId: period?.internalPeriodId,
    periodNumber: period?.periodNumber as number,
    points,
    teamId: teamId,
    teamName: group?.teams.find((team) => team._id === teamId)?.name,
    groupId: group?._id as string,
  }
}

/**
 * Convert a date to a local ISO string with timezone offset
 * @param date - The date to convert
 * @return The local ISO string
 */
function toLocalISOString(date: Date): string {
  // Get the timezone offset in minutes and convert it to milliseconds
  const timezoneOffset = date.getTimezoneOffset() * 60000

  // Adjust the date to local time
  const localTime = new Date(date.getTime() - timezoneOffset)

  // Format the adjusted time in ISO format and slice off the 'Z' at the end
  const isoString = localTime.toISOString().slice(0, -1)

  // Append the local timezone offset in the format ±HH:MM
  const offset = -date.getTimezoneOffset()
  const offsetSign = offset >= 0 ? '+' : '-'
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0')
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0')
  const formattedOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`

  return isoString + formattedOffset
}

/**
 * Play audio based on the type.
 * @param type - The type of audio to play.
 */
function playAudio(type: string) {
  const audioMap: {
    [key: string]: string
  } = {
    'Bizi Bonus': 'https://storage.googleapis.com/bizi-asset/AppAudios/bonus-audio-v2.mp3',
    Flag: 'https://storage.googleapis.com/bizi-asset/AppAudios/flag-audio-v1.mp3',
  }
  const audioLink = audioMap[type]

  // Play audio only in QA and Dev environments
  if (audioLink) {
    const audio = new Audio(audioLink)
    audio.play()
  }
}

export {
  getPeriodSuffix,
  getCurrentTimeSpan,
  getPeriodName,
  getPeriodNumber,
  getMemberStatus,
  getShortName,
  getValidTimeSpans,
  getMemberStatusColor,
  captureSentryException,
  captureSentryMessage,
  logout,
  getAmountToNextStatus,
  getNextStatus,
  getUserTime,
  getLevelThreshold,
  getCurrencyName,
  getDateStr,
  isProd,
  removeLocalStorage,
  getLastFourDigits,
  getFirstFourDigits,
  sendCustomEvent,
  getAttendanceDescription,
  lazyWithRetry,
  getNameStr,
  getDailyBiziBonusAmount,
  getTeacherName,
  calculateRatio,
  getActivityObject,
  getMemberStatusTextColor,
  getTeacherShortName,
  toLocalISOString,
  getPreviousTimeSpan,
  getAllMemberStatusBeforeGivenStatus,
  getFlagPoints,
  playAudio,
}
